import React, {Fragment} from "react";
import {Link} from "gatsby";
import {CenteredContainer, PatientSupportContainer} from "../../style";
import patientSupportBadge from "../../assets/images/patient-support-badge.svg";

const PatientSupport = (props) => {
  const {showQuestions} = props;
  const questions = [{
    text: "What kind of cancer would you like to know about?",
    link: "/patient-support/#cancer-type",
  }, {
    text: "What kind of treatment do you want to know about?",
    link: "/patient-support/#treatment-type",
  }, {
    text: "Watch our experts answer",
    link: "/patient-support/#experts-answer",
  }, {
    text: "Consult a tumour board",
    link: "/patient-support/#consult-tumor-board"
  }];

  return (
    <Fragment>
      <CenteredContainer className="patient-support section-spacing">
        <img src={patientSupportBadge} className="logo" alt="logo"/>
        <h2 className="heading-text">
          It takes more than medicines
          <span>to fight cancer</span>
        </h2>
        <div className="small-text">
          If you are a cancer patient or a caregiver, unbiased, independent and easy to
          understand knowledge is what you need. And that is what you'll find here.
          Our videos are developed by practicing oncologists with years of experience
          dealing with the disease and patients suffering from the disease. Our goal is 
          simple: to answer your question in a way that'll clear your mind and put you
          back in control of your life.
        </div>
      </CenteredContainer>
      {showQuestions && 
        <PatientSupportContainer>
          <ul>
            {
              questions.map(el => {
                const {text, link} = el;

                return (
                  <li key={text}>
                    <Link to={link} target="_self">{text}</Link>
                  </li>
                )
              })
            }
          </ul>
        </PatientSupportContainer>
      }
    </Fragment>
  )
};

PatientSupport.defaultProps = {
  showQuestions: true
};

export default PatientSupport;