import React from "react";
import {GlobalStyle, MainContainer} from "../style";
import Head from "../components/common/head";
import NavBar from "../components/common/navBar";
import FooterBar from "../components/common/footer";
import ContactUs from "../components/patient-support/contactUs";
import VideoFilter from "../components/patient-support/videoFilter";
import PatientSupportSection from "../components/homepage/patientSupport";

const PatientSupport = () => {
  return (
    <MainContainer>
      <GlobalStyle/>
      <Head/>
      <NavBar/>
      <PatientSupportSection showQuestions={false}/>
      <VideoFilter bindScrollEvent={false}/>
      <ContactUs/>
      <FooterBar/>
    </MainContainer>
  );
};

export default PatientSupport;