import React from "react";
import {ContactUsContainer} from "../../style";

const ContactUs = () => {
  
  return (
    <ContactUsContainer className="section-spacing">
      <div className="question-block" id="ask-us">
        <div className="question">
          Can't find an answer? Ask us.
        </div>
        <textarea className="input-field"/>
        <div className="flex-container vertical-middle">
          <div className="text text-uppercase">E-mail</div>
          <div className="flexbox email">
            <input type="email" className="input-field"/>
          </div>
          <div className="input">
            <button type="button" className="btn deep-green text-uppercase">Submit</button>
          </div>
        </div>
      </div>
      <div className="question-block" id="consult-tumor-board">
        <div className="question">
          Consult a tumour board
        </div>
        <textarea className="input-field"/>
        <div className="flex-container vertical-middle">
          <div className="text text-uppercase">E-mail</div>
          <div className="flexbox email">
            <input type="email" className="input-field"/>
          </div>
          <div className="input">
            <button type="button" className="btn deep-green text-uppercase">Submit</button>
          </div>
        </div>
      </div>
    </ContactUsContainer>
  );
};

export default ContactUs;